<template>
  <div class="notification-card p-3 w-100 d-flex align-items-start">
    <div v-if="isCharacterRelationshipRequest" class="request w-100">
      <div class="title mb-2 bold">Character Relation</div>
      <div v-if="!isAcceptedRelationshipRequest && !isDeniedRelationshipRequest" class="rest-info">
        <span class="name clickable" @click="toActor()"> @{{ username }} </span>
        <span> wants to add </span>
        <span class="character clickable" @click="toCharacter(actionObject.character2)">
          {{ actionObject.character2.info.name }}
        </span>
        <span> to </span>
        <span class="character clickable" @click="toCharacter(actionObject.character1)">
          {{ actionObject.character1.info.name }}
        </span>
        <span>'s profile as the following relation: "{{ actionObject.character1_status }}" </span>
      </div>

      <div v-if="isAcceptedRelationshipRequest" class="rest-info">
        <span class="name clickable" @click="toActor()"> @{{ username }} </span>
        <span> accepted a relation between </span>
        <span class="character clickable" @click="toCharacter(actionObject.character1)">
          {{ actionObject.character1.info.name }}
        </span>
        <span> and </span>
        <span class="character clickable" @click="toCharacter(actionObject.character2)">
          {{ actionObject.character2.info.name }}
        </span>
        <span>. </span>
      </div>

      <div v-if="isDeniedRelationshipRequest" class="rest-info">
        <span class="name clickable" @click="toActor()"> @{{ username }} </span>
        <span> rejected a relation between </span>
        <span class="character clickable" @click="toCharacter(actionObject.character1)">
          {{ actionObject.character1.info.name }}
        </span>
        <span> and </span>
        <span class="character clickable" @click="toCharacter(actionObject.character2)">
          {{ actionObject.character2.info.name }}
        </span>
        <span>. </span>
      </div>

      <div v-if="!isAcceptedRelationshipRequest && !isDeniedRelationshipRequest" class="actions d-flex mt-2 w-100">
        <div v-if="actionTaken" class="action-happened w-100">
          <ion-button class="w-100 btn-actioned" color="medium" disabled>
            <span v-if="isApproved"> Request approved </span>
            <span v-if="isDenied"> Request denied </span>
          </ion-button>
        </div>
        <div v-else class="d-flex w-100">
          <ion-button class="w-100 mx-3 accept-btn" @click="acceptRelationship">
            <ChLoading size="sm" v-if="loading && loadingAction === 'accept'" class="spinner" />
            <span v-else>Accept</span>
          </ion-button>
          <ion-button class="mx-3 deny-btn w-100" @click="denyRelationship">
            <ChLoading size="sm" v-if="loading && loadingAction === 'deny'" class="spinner" />
            <span v-else>Deny</span>
          </ion-button>
        </div>
      </div>
    </div>

    <div v-else-if="isCharacterTransferRequest" class="request w-100">
      <div class="title mb-2 bold">Character Transfer</div>
      <div v-if="!isAcceptedRelationshipRequest && !isDeniedRelationshipRequest" class="rest-info">
        <span v-if="!userInitiatedCharacterTransferRequest">
          <span class="name clickable" @click="toActor()"> @{{ username }} </span>
          <span> wants to transfer ownership of </span>
          <span class="character clickable" @click="toCharacter(actionObject.character)">
            {{ actionObject.character.info.name }}
          </span>
          <span> to you.</span>
        </span>
        <span v-else>
          <span> Your request to transfer ownership of </span>
          <span class="character clickable" @click="toCharacter(actionObject.character)">
            {{ actionObject.character.info.name }}
          </span>
          <span> to {{ actionObject.user_to.username }}</span>
        </span>
        <pre>Notes, terms, conditions:<br>{{ actionObject.note }}</pre>
      </div>

      <div v-if="isAcceptedTransferRequest" class="rest-info">
        <span class="name clickable" @click="toActor()"> @{{ username }} </span>
        <span> accepted your transfer request for </span>
        <span class="character clickable" @click="toCharacter(actionObject.character)">
          {{ actionObject.character.info.name }}
        </span>
        <span>. </span>
      </div>

      <div v-if="isDeniedTransferRequest" class="rest-info">
        <span class="name clickable" @click="toActor()"> @{{ username }} </span>
        <span> rejected your transfer request for </span>
        <span class="character clickable" @click="toCharacter(actionObject.character)">
          {{ actionObject.character.info.name }}
        </span>
        <span>. </span>
      </div>

      <div v-if="!isAcceptedTransferRequest && !isDeniedTransferRequest" class="actions d-flex mt-2 w-100">
        <div v-if="actionTaken" class="action-happened w-100">
          <ion-button class="w-100 btn-actioned" color="medium" disabled>
            <span v-if="isApproved"> Transfer accepted </span>
            <span v-if="isDenied"> Transfer declined </span>
          </ion-button>
        </div>
        <div v-else class="d-flex w-100 mt-4">
          <ion-button class="w-100 mx-3 accept-btn" @click="acceptTransfer">
            <ChLoading size="sm" v-if="loading && loadingAction === 'accept'" class="spinner" />
            <span v-else>Accept</span>
          </ion-button>
          <ion-button class="mx-3 deny-btn w-100" @click="rejectTransfer">
            <ChLoading size="sm" v-if="loading && loadingAction === 'deny'" class="spinner" />
            <span v-else>Decline</span>
          </ion-button>
        </div>
      </div>
    </div>
    <div v-else-if="isCustom" class="request w-100">
      <div class="rest-info ml-3 mt-1" :class="notificationColor">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="notification.description"></span>
      </div>
    </div>
    <div v-else class="rest-types">
      <div class="rest-info ml-3 mt-1" :class="notificationColor">
        <span v-if="preUserText" class="action">{{ preUserText }}</span>
        <span class="name clickable" @click="toActor()">
          {{ username }}
        </span>
        <span class="action" :class="{ clickable: hasActionLink() }" @click.prevent="toAction">
          {{ action }}
        </span>
        <span class="character clickable" @click="toTarget()"
          >{{ target }}{{ !postUserText && action2 ? ':' : '' }}
        </span>
        <span v-if="postUserText" class="action">{{ postUserText }}</span>
        <span class="action ml-1" :class="{ clickable: hasActionPageLink() }" @click.prevent="toActionPage">
          {{ action2 }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import logger from '@/shared/services/logger';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Reaction from './Reaction/index.vue';
import { Character, INotification } from '@/shared/types/static-types';
import {
  reactionsNames,
  worldReactionsNames,
  blabReactionsNames,
  visualReactionsNames,
} from '@/shared/statics/reactions';
import constants from '@/shared/statics/constants';
import { stripHtmlTags } from '@/shared/utils/string';
import {
  acceptCharacterRelationshipRequest,
  denyCharacterRelationshipRequest,
} from '@/shared/actions/characterRelationships';
import { acceptPendingCharacterTransfer, rejectPendingCharacterTransfer } from '@/shared/actions/characters';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';

@Options({
  name: 'NotificationCard',
  components: {
    Reaction,
  },
})
export default class NotificationCard extends Vue {
  @Prop({ default: () => ({}) }) notification!: INotification;
  stripHtmlTags = stripHtmlTags;
  visualReactionsNames = visualReactionsNames;

  public loadingAction = '';
  public loading = false;

  public get currentUser() {
    const { user } = authStore();
    return user.value;
  }

  public get actionTaken() {
    return this.actionObject?.state !== 'R';
  }

  public get isApproved() {
    return this.actionObject?.state === 'A';
  }

  public get isDenied() {
    return this.actionObject?.state === 'X';
  }

  public async acceptRelationship() {
    try {
      this.loading = true;
      this.loadingAction = 'accept';
      const relationshipId: string = this.notification.action_object.id;
      await acceptCharacterRelationshipRequest(relationshipId);
      this.$emit('refetch');
      toast.show('Accepted the relation request', 'nonative', 'success');
    } catch (error) {
      toast.show('Error accepting the relation request', 'nonative', 'danger');
    } finally {
      this.loading = false;
    }
  }

  public async denyRelationship() {
    try {
      this.loading = true;
      this.loadingAction = 'deny';
      const relationshipId: string = this.notification.action_object.id;
      await denyCharacterRelationshipRequest(relationshipId);
      this.$emit('refetch');
      toast.show('Denied the relation request', 'nonative', 'success');
    } catch (error) {
      toast.show('Error denying the relation request', 'nonative', 'danger');
    } finally {
      this.loading = false;
    }
  }

  public async acceptTransfer() {
    try {
      this.loading = true;
      this.loadingAction = 'accept';
      const relationshipId: string = this.notification.action_object.id;
      await acceptPendingCharacterTransfer(relationshipId);
      this.$emit('refetch');
      toast.show('Character transfer complete', 'nonative', 'success');
    } catch (error) {
      toast.show('Encountered an error while completing character transfer', 'nonative', 'danger');
    } finally {
      this.loading = false;
    }
  }

  public async rejectTransfer() {
    try {
      this.loading = true;
      this.loadingAction = 'deny';
      const relationshipId: string = this.notification.action_object.id;
      await rejectPendingCharacterTransfer(relationshipId);
      this.$emit('refetch');
      toast.show('Rejected character transfer', 'nonative', 'success');
    } catch (error) {
      toast.show('Encountered an error while rejecting character transfer', 'nonative', 'danger');
    } finally {
      this.loading = false;
    }
  }

  public sfActivityActions = {
    created_world: 'Explore it now!',
    created_character: 'Have a look.',
    created_marketplacelisting: 'Take a quick look.',
    created_blab: 'Click here to check it out.',
    sunshined_reply: 'Click here to check it out.',
  };

  public get actionObject() {
    return this.notification.action_object;
  }

  public get preUserText() {
    if (this.notification.verb === constants.notificationVerbs.USER_CHEER_CHART) {
      // const username = get(this.notification.action_object, 'info')
      //   ? get(this.notification.action_object, 'author.username')
      //   : get(this.notification.action_object, 'seller')
      //   ? get(this.notification.action_object, 'seller.username', '')
      //   : get(this.notification.action_object, 'user.username', 'someone');
      return `Wow! You are good luck! Your cheer specifically got `;
    } else if (this.notification.verb === constants.notificationVerbs.HELP_CHEER) {
      // const username = get(this.notification.action_object.entity, 'info')
      //   ? get(this.notification.action_object.entity, 'author.username')
      //   : get(this.notification.action_object.entity, 'seller')
      //   ? get(this.notification.action_object.entity, 'seller.username', '')
      //   : get(this.notification.action_object.entity, 'user.username', '');
      return `Your cheer helped `;
    } else if (this.notification.verb === constants.notificationVerbs.COLLECTIBLE_RECEIVED) {
      return 'Congratulations! ';
    } else if (this.notification.verb === constants.notificationVerbs.SENT_FAN_ART) {
      return 'You received FanWork from';
    } else if (this.notification.verb === constants.notificationVerbs.DECORATION_RECEIVED) {
      return `You received ${this.notification.data.decoration_count} Decorations from`;
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_ENDED) {
      return 'Your';
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_WINNER) {
      return 'You';
    }

    return '';
  }

  public get postUserText() {
    if (this.notification.verb === constants.notificationVerbs.ACTIVITY_CHEER_CHART) {
      return `, because of your ${
        get(this.notification.action_object, 'action') === constants.followFeedActions.FANWORK_SUBMITTED
          ? 'FanWork'
          : 'update'
      }!`;
    } else if (this.notification.verb === constants.notificationVerbs.HELP_CHEER) {
      return ' for the entire community to see!';
    } else if (this.notification.verb === constants.notificationVerbs.USER_CHEER_CHART) {
      return ' to the entire community!';
    } else if (this.notification.verb === constants.notificationVerbs.REACTION_FEEDBACK) {
      return ` submission with:`;
    } else if (this.notification.verb === constants.notificationVerbs.FANART_REACTION) {
      return ` got a reaction:`;
    } else if (this.notification.verb === constants.notificationVerbs.QUEST_UPDATE) {
      return this.notification.description ? ` ${this.notification.description}` : '';
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_MILESTONE) {
      return ' raffle!';
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_ENDED) {
      return '  Check your email for next steps!';
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_WINNER) {
      return ' raffle, check your email for instructions!';
    }
  }

  public get username() {
    if (get(this.notification.action_object, 'commented_as')) {
      if (get(this.notification.action_object, 'commented_as.type') === constants.commentedAsTypes.USER) {
        const {
          actor: { username },
        } = this.notification;
        return username;
      } else {
        return get(this.notification.action_object, 'commented_as.info.name', 'Unknown');
      }
    } else if (
      [constants.notificationVerbs.CHAR_FEATURE, constants.notificationVerbs.CHAR_VISUAL].includes(
        this.notification.verb
      )
    ) {
      return get(this.notification.action_object, 'character.info.name', 'Unknown');
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_SEND_REPLY) {
      if (get(this.notification.action_object, 'reply_as_char')) {
        return get(this.notification.action_object, 'reply_as_char.info.name', 'Unknown');
      } else {
        const {
          actor: { username },
        } = this.notification;
        return username;
      }
    } else if (this.notification.verb === constants.notificationVerbs.CHAR_TRENDING) {
      return get(this.notification.action_object, 'info.name', 'Unknown');
    } else if (
      [constants.notificationVerbs.SENT_FAN_ART, constants.notificationVerbs.DECORATION_RECEIVED].includes(
        this.notification.verb
      )
    ) {
      return ` ${get(this.notification.actor, 'username')} `;
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_PARENT_REPLY) {
      if (get(this.notification.action_object, 'reply_as_char')) {
        return get(this.notification.action_object, 'reply_as_char.info.name', 'Unknown');
      } else {
        const {
          actor: { username },
        } = this.notification;
        return username;
      }
    } else if (
      [constants.notificationVerbs.SENT_FEEDBACK, constants.notificationVerbs.SENT_DEC_FEEDBACK].includes(
        this.notification.verb
      )
    ) {
      return ` ${get(this.notification.actor, 'username')} `;
    } else if (this.notification.verb === constants.notificationVerbs.WORLD_TREND) {
      return get(this.notification.action_object, 'name', 'Unknown');
    } else if (this.notification.verb === constants.notificationVerbs.REACTION_FEEDBACK) {
      return ` ${get(this.notification.actor, 'username')} `;
    } else if (this.notification.verb === constants.notificationVerbs.CHAR_SECTION) {
      return get(this.notification.action_object, 'info.name', 'Unknown');
    } else if (this.notification.verb === constants.notificationVerbs.ACTIVITY_CHEER_CHART) {
      if (!get(this.notification.action_object, 'action') || !get(this.notification.action_object, 'entity')) {
        // For supporting old activity selection notifications.
        return get(this.notification.action_object, 'info.name')
          ? get(this.notification.action_object, 'info.name', '')
          : get(this.notification.action_object, 'name')
          ? get(this.notification.action_object, 'name', '')
          : get(this.notification.action_object, 'title', 'Your activity');
      }
      // For supporting updated activity selection notifications.
      else if (
        [
          'created_character',
          'updated_char_text',
          'updated_char_fields',
          'updated_char_visuals',
          'updated_char_profile_img',
          'fanart_submitted',
          'fanart_featured',
        ].includes(get(this.notification.action_object, 'action'))
      ) {
        return get(this.notification.action_object.entity, 'info.name', '');
      } else if (
        ['created_blab', 'created_marketplacelisting', 'sunshined_reply'].includes(
          get(this.notification.action_object, 'action')
        )
      ) {
        return get(this.notification.action_object.entity, 'title', '');
      } else if (
        [
          'created_world',
          'created_story_world',
          'updated_world_text',
          'updated_world_fields',
          'updated_world_visuals',
          'updated_world_profile_img',
          'updated_social_world_text',
          'updated_story_world_text',
          'updated_social_world_text',
        ].includes(this.notification.action_object.action)
      ) {
        return get(this.notification.action_object.entity, 'name', '');
      }
    } else if (this.notification.verb === constants.notificationVerbs.USER_CHEER_CHART) {
      const username = get(this.notification.action_object, 'info')
        ? get(this.notification.action_object, 'author.username')
        : get(this.notification.action_object, 'seller')
        ? get(this.notification.action_object, 'seller.username', '')
        : get(this.notification.action_object, 'user.username', 'someone');

      return `${username}’s `;
    } else if (this.notification.verb === constants.notificationVerbs.HELP_CHEER) {
      const username = get(this.notification.action_object.entity, 'info')
        ? get(this.notification.action_object.entity, 'author.username')
        : get(this.notification.action_object.entity, 'seller')
        ? get(this.notification.action_object.entity, 'seller.username', '')
        : get(this.notification.action_object.entity, 'user.username', '');
      return `${username}’s`;
    } else if (
      [
        constants.notificationVerbs.EVENT_SUBMISSION_REVIEWED,
        constants.notificationVerbs.COLLECTIBLE_RECEIVED,
      ].includes(this.notification.verb)
    ) {
      return null;
    } else if (this.notification.verb === constants.notificationVerbs.SF_ACTIVITY_CREATED) {
      return this.notification.actor.username;
    } else if (
      [constants.notificationVerbs.MISSED_MESSAGES, constants.notificationVerbs.QUEST_UPDATE].includes(
        this.notification.verb
      )
    ) {
      return '';
    } else if (
      [
        constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_ACCEPTED,
        constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_REJECTED,
      ].includes(this.notification.verb)
    ) {
      return this.notification.action_object.user_to.username;
    } else if ([constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_SENT].includes(this.notification.verb)) {
      return this.notification.action_object.user_from.username;
    } else if (
      [
        constants.notificationVerbs.FANART_REACTION,
        constants.notificationVerbs.RAFFLE_MILESTONE,
        constants.notificationVerbs.RAFFLE_WINNER,
      ].includes(this.notification.verb)
    ) {
      return '';
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_ENDED) {
      return ` ${this.notification.action_object.title} `;
    } else if (
      [constants.notificationVerbs.GIFTED_ITEM, constants.notificationVerbs.GIFTED_VIRTUAL_ITEMS].includes(
        this.notification.verb
      )
    ) {
      return ` ${this.notification.actor.username} `;
    } else if (this.notification.verb === constants.notificationVerbs.THANKS_GIFT_MSG) {
      return ` ${this.notification.actor.username} `;
    } else {
      try {
        const {
          actor: { username },
        } = this.notification;
        return username;
      } catch (e) {
        return '';
      }
    }
  }

  public get target() {
    if (this.notification.verb === constants.notificationVerbs.RC_STARTER_REQ) {
      return '';
    } else if (
      [
        constants.notificationVerbs.WORLD_REACTION,
        constants.notificationVerbs.WORLD_USER_REQ,
        constants.notificationVerbs.WORLD_USER_ACCP,
        constants.notificationVerbs.WORLD_CHAR_REQ,
        constants.notificationVerbs.WORLD_CHAR_ACCP,
      ].includes(this.notification.verb)
    ) {
      if (
        get(this.notification.target, 'type') === 'socialspace' ||
        get(this.notification.target, 'type') === 'story'
      ) {
        return get(this.notification.target, 'name', '');
      } else {
        return get(this.notification.target, 'title', '');
      }
    } else if (
      [
        constants.notificationVerbs.CHAR_FEATURE,
        constants.notificationVerbs.CHAR_VISUAL,
        constants.notificationVerbs.CHAR_TRENDING,
        constants.notificationVerbs.WORLD_TREND,
      ].includes(this.notification.verb)
    ) {
      return '';
    } else if ([constants.notificationVerbs.BLAB_REACTION].includes(this.notification.verb)) {
      return get(this.notification.target, 'title', '');
    } else if ([constants.notificationVerbs.BLAB_REPLY_REACTION].includes(this.notification.verb)) {
      return get(this.notification, 'target.blab.title');
    } else if ([constants.notificationVerbs.BLAB_SHINE_REPLY].includes(this.notification.verb)) {
      return get(this.notification.target, 'title', '');
    } else if ([constants.notificationVerbs.BLAB_SEND_REPLY].includes(this.notification.verb)) {
      return get(this.notification.target, 'title', '');
    } else if ([constants.notificationVerbs.BLAB_PARENT_REPLY].includes(this.notification.verb)) {
      return get(this.notification.target, 'blab.title', '');
    } else if ([constants.notificationVerbs.CHAR_SECTION].includes(this.notification.verb)) {
      return get(this.notification.target, 'name', '');
    } else if (this.notification.verb === constants.notificationVerbs.FOLLOW_USER)
      if (this.notification.action_object.info) {
        return this.notification.action_object.info.name;
      } else {
        return '';
      }
    else if (
      [
        constants.notificationVerbs.ACTIVITY_CHEER_CHART,
        constants.notificationVerbs.USER_CHEER_CHART,
        constants.notificationVerbs.HELP_CHEER,
      ].includes(this.notification.verb)
    ) {
      return ' Cheer Chart';
    } else if (this.notification.verb === constants.notificationVerbs.CHEER) {
      return '';
    } else if ([constants.notificationVerbs.GIFTED_ITEM].includes(this.notification.verb)) {
      return ` Say thanks!`;
    } else if (this.notification.verb === constants.notificationVerbs.GIFTED_VIRTUAL_ITEMS) {
      return ` Claim now!`;
    } else if (this.notification.verb === constants.notificationVerbs.EVENT_SUBMISSION_REVIEWED) {
      return get(this.notification.target, 'name', '');
    } else if (this.notification.verb === constants.notificationVerbs.COLLECTIBLE_RECEIVED) {
      return get(this.notification.target, 'name', 'See your inventory');
    } else if (
      [constants.notificationVerbs.SENT_FAN_ART, constants.notificationVerbs.DECORATION_RECEIVED].includes(
        this.notification.verb
      )
    ) {
      return ' Click here to view it!';
    } else if (this.notification.verb === constants.notificationVerbs.SENT_FEEDBACK) {
      if (get(this.notification.action_object, 'type') === 'story') {
        return ` ${this.notification.action_object.name}'s FanWork`;
      } else {
        return `${get(this.notification.action_object, 'info.name', 'Unknown')}'s FanWork`;
      }
    } else if (this.notification.verb === constants.notificationVerbs.SENT_DEC_FEEDBACK) {
      return `${get(this.notification.action_object, 'info.name', 'Unknown')}'s Decoration.`;
    } else if (this.notification.verb === constants.notificationVerbs.REACTION_FEEDBACK) {
      if (get(this.notification.action_object, 'type') === 'story') {
        return ` ${this.notification.action_object.name}'s FanWork`;
      } else {
        return ` ${get(this.notification.action_object, 'info.name')}'s FanWork`;
      }
    } else if (this.notification.verb === constants.notificationVerbs.SF_ACTIVITY_CREATED) {
      return get(this.sfActivityActions, get(this.notification.action_object, 'action', 'none'), 'Check it out!');
    } else if (this.notification.verb === constants.notificationVerbs.FANART_REACTION) {
      if (get(this.notification.target.entity, 'type') === 'world') {
        return this.notification.target.entity.name;
      } else {
        return get(this.notification.target.entity, 'info.name');
      }
    } else if (this.notification.verb === constants.notificationVerbs.IMAGE_REACTION) {
      if (get(this.notification.target, 'entity.type') === 'world') {
        return ` ${this.notification.target.entity.name}'s art`;
      } else if (get(this.notification.target, 'entity.type') === 'character') {
        return ` ${get(this.notification.target, 'entity.info.name', 'Unknown')}'s art`;
      }
      return ' an art';
    } else if (this.notification.verb === constants.notificationVerbs.QUEST_SUBMISSION_REACTION) {
      return 'your quest submission';
    } else if (this.notification.verb === constants.notificationVerbs.REQUEST_ROOM) {
      return get(this.notification.target, 'title', '');
    } else if ([constants.notificationVerbs.CHATROOM_REPLY].includes(this.notification.verb)) {
      return get(this.notification.target, 'title', '');
    } else if (this.notification.verb === constants.notificationVerbs.USER_JOINED) {
      return get(this.notification.target, 'name', '');
    } else if (this.notification.verb === constants.notificationVerbs.MISSED_MESSAGES) {
      return ` Explore now to see what's new!`;
    } else if (this.notification.verb === constants.notificationVerbs.ADDED_CHAR_WORLD) {
      return this.notification.target.name;
    } else if (this.notification.verb === constants.notificationVerbs.QUEST_UPDATE) {
      return `${this.notification.target.name}!`;
    } else if (this.notification.verb === constants.notificationVerbs.DECORATION_REACTION) {
      return this.notification?.action_object?.info?.name
        ? ` ${this.notification?.action_object?.info?.name}'s decoration`
        : 'decoration';
    } else if (
      [constants.notificationVerbs.COMMENT, constants.notificationVerbs.COMMENT_REPLY].includes(this.notification.verb)
    ) {
      if (this.notification.action_object.root_commented_on_type === constants.commentedAsTypes.CHARACTER) {
        return get(this.notification, 'target.info.name', 'Unknown');
      } else if (this.notification.action_object.root_commented_on_type === constants.commentedAsTypes.USER) {
        if (this.notification.target.id === this.currentUser.id) {
          return 'your profile';
        } else {
          return `@${this.notification.target.username}'s profile`;
        }
      }
    } else if (
      [constants.notificationVerbs.RAFFLE_MILESTONE, constants.notificationVerbs.RAFFLE_WINNER].includes(
        this.notification.verb
      )
    ) {
      return ` "${get(this.notification.target, 'title')}"`;
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_ENDED) {
      return ` `;
    } else if (this.notification.verb === constants.notificationVerbs.THANKS_GIFT_MSG) {
      return ``;
    }
    return get(this.notification, 'target.info.name', 'Unknown');
  }

  public get action() {
    if (this.notification.verb === constants.notificationVerbs.RC_CHAR_REQ) {
      return ' requested rolechat with your character ';
    } else if (this.notification.verb === constants.notificationVerbs.RC_STARTER_REQ) {
      return ' requested rolechat against your starter';
    } else if (this.notification.verb === constants.notificationVerbs.COMMENT_REPLY) {
      return ' replied to a comment on ';
    } else if (this.notification.verb === constants.notificationVerbs.WORLD_USER_REQ) {
      return ' has requested to join your social space ';
    } else if (this.notification.verb === constants.notificationVerbs.WORLD_USER_ACCP) {
      return ' accepted your request into ';
    } else if (this.notification.verb === constants.notificationVerbs.WORLD_CHAR_REQ) {
      return ` has submitted ${get(this.notification.action_object, 'info.name', '')} to join your world `;
    } else if (get(this.notification.action_object, 'commented_as')) {
      return ' commented on ';
    } else if (this.notification.verb === constants.notificationVerbs.WORLD_CHAR_ACCP) {
      return ` accepted your character ${get(this.notification.action_object, 'info.name', '')} in `;
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_REPLY_REACTION) {
      return ' reacted to your reply on ';
    } else if (this.notification.verb === constants.notificationVerbs.FANART_REACTION) {
      return 'Your fan art of ';
    } else if (get(this.notification.action_object, 'reaction')) {
      return ' reacted to ';
    } else if (this.notification.verb === constants.notificationVerbs.CHAR_FEATURE) {
      return ` was featured! Check the home page and you may catch a glimpse of ${get(
        this.notification.action_object.character,
        'info.name',
        ''
      )}  at some point!
        `;
    } else if (this.notification.verb === constants.notificationVerbs.CHAR_VISUAL) {
      return "'s art was featured! Check the featured page to see it live!";
    } else if (this.notification.verb === constants.notificationVerbs.CHAR_TRENDING) {
      return ` is trending! ${get(
        this.notification.action_object,
        'info.name',
        ''
      )} must have resonated with the community!`;
    } else if (this.notification.verb === constants.notificationVerbs.WORLD_TREND) {
      return ' is trending! Congrats!! 🥳';
    } else if (this.notification.verb === constants.notificationVerbs.SENT_FAN_ART) {
      if (get(this.notification, 'action_object.type') === 'story') {
        return ` for world ${get(this.notification, 'action_object.name', 'Unknown')}!`;
      } else {
        return ` for character ${get(this.notification, 'action_object.info.name', 'Unknown')}!`;
      }
    } else if (this.notification.verb === constants.notificationVerbs.DECORATION_RECEIVED) {
      return ` for character ${get(this.notification, 'action_object.info.name', 'Unknown')}!`;
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_SHINE_REPLY) {
      return ' pinned your reply on ';
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_SEND_REPLY) {
      return ' replied on ';
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_PARENT_REPLY) {
      return ' replied to a comment on ';
    } else if (this.notification.verb === constants.notificationVerbs.CHAR_SECTION) {
      return ' was featured in section ';
    } else if (this.notification.verb === constants.notificationVerbs.FOLLOW_USER) {
      if (get(this.notification.action_object, 'info.name')) {
        return ' followed your character ';
      } else {
        return ' followed you!';
      }
    } else if (this.notification.verb === constants.notificationVerbs.CHEER) {
      const cheerCount = get(this.notification, 'data.cheer_count', '');
      const notifAction = get(this.notification.action_object, 'action');

      if ([constants.followFeedActions.CREATED_CHARACTER].includes(notifAction)) {
        return ` cheered ${cheerCount} times for ${get(this.notification.action_object, 'entity.info.name')} creation!`;
      } else if (
        [
          constants.followFeedActions.UPDATED_CHAR_TEXT,
          constants.followFeedActions.UPDATED_CHAR_FIELDS,
          constants.followFeedActions.UPDATED_CHAR_VISUALS,
          constants.followFeedActions.UPDATED_CHAR_PROFILE_IMG,
          constants.followFeedActions.FANART_FEATURED,
        ].includes(notifAction)
      ) {
        return ` cheered ${cheerCount} times for your ${get(
          this.notification.action_object,
          'entity.info.name'
        )} update!`;
      } else if (
        [
          constants.followFeedActions.CREATED_WORLD,
          constants.followFeedActions.CREATED_SPACE,
          constants.followFeedActions.CREATED_STORY_WORLD,
        ].includes(notifAction)
      ) {
        return ` cheered ${cheerCount} times for ${get(this.notification.action_object, 'entity.name')} creation!`;
      } else if (
        [
          constants.followFeedActions.UPDATED_WORLD_TEXT,
          constants.followFeedActions.UPDATED_WORLD_FIELDS,
          constants.followFeedActions.UPDATED_WORLD_VISUALS,
          constants.followFeedActions.UPDATED_WORLD_PROFILE_IMG,
          constants.followFeedActions.UPDATED_STORY_WORLD_TEXT,
          constants.followFeedActions.UPDATED_SOCIAL_TEXT,
        ].includes(notifAction)
      ) {
        return ` cheered ${cheerCount} times for your ${get(this.notification.action_object, 'entity.name')} update!`;
      } else if ([constants.followFeedActions.CREATED_BLAB].includes(notifAction)) {
        return ` cheered ${cheerCount} times for your ${get(this.notification.action_object, 'entity.title')} post!`;
      } else if ([constants.followFeedActions.SUNSHINED_REPLY].includes(notifAction)) {
        return ` cheered ${cheerCount} times for sunshining a reply on ${get(
          this.notification.action_object,
          'entity.title'
        )} post!`;
      } else if ([constants.followFeedActions.CREATED_MARKETPLACELISTING].includes(notifAction)) {
        return ` cheered ${cheerCount} times for your ${get(this.notification.action_object, 'entity.title')} listing!`;
      } else if (notifAction === constants.followFeedActions.FANWORK_SUBMITTED) {
        return ` cheered ${cheerCount} times for your ${get(
          this.notification.action_object,
          'entity.info.name'
        )} FanWork!`;
      }
      return ` cheered ${cheerCount} times for your update!`;
    } else if (this.notification.verb === constants.notificationVerbs.ACTIVITY_CHEER_CHART) {
      return ` just got featured on the`;
    } else if (this.notification.verb === constants.notificationVerbs.USER_CHEER_CHART) {
      const name = get(this.notification.action_object, 'info')
        ? this.notification.action_object.info.name
        : get(this.notification.action_object, 'name')
        ? this.notification.action_object.name
        : get(this.notification.action_object, 'title', 'activity');

      return ` ${name} featured on the `;
    } else if (this.notification.verb === constants.notificationVerbs.HELP_CHEER) {
      const name = get(this.notification.action_object.entity, 'info')
        ? this.notification.action_object.entity.info.name
        : get(this.notification.action_object.entity, 'name')
        ? this.notification.action_object.entity.name
        : get(this.notification.action_object.entity, 'title', 'activity');

      return ` ${name} get featured on the`;
    } else if (this.notification.verb === constants.notificationVerbs.EVENT_SUBMISSION_REVIEWED) {
      return 'Your submission for ';
    } else if (this.notification.verb === constants.notificationVerbs.COLLECTIBLE_RECEIVED) {
      return 'You just received an item! ';
    } else if (
      [constants.notificationVerbs.SENT_FEEDBACK, constants.notificationVerbs.SENT_DEC_FEEDBACK].includes(
        this.notification.verb
      )
    ) {
      return 'responded to your ';
    } else if (
      [constants.notificationVerbs.REACTION_FEEDBACK, constants.notificationVerbs.QUEST_SUBMISSION_REACTION].includes(
        this.notification.verb
      )
    ) {
      return ' just reacted to your';
    } else if (this.notification.verb === constants.notificationVerbs.REQUEST_ROOM) {
      return ' has requested to join your chatroom ';
    } else if (this.notification.verb === constants.notificationVerbs.MISSED_MESSAGES) {
      return ` You have ${this.notification.data.unread_count} missed messages in your social spaces.`;
    } else if (this.notification.verb === constants.notificationVerbs.CHATROOM_REPLY) {
      return ` replied to ${
        get(this.notification.action_object, 'reply_to.image_url')
          ? 'your image'
          : `"${this.truncateReplyText(get(this.notification.action_object, 'reply_to.text', ''))}"`
      } on `;
    } else if (this.notification.verb === constants.notificationVerbs.USER_JOINED) {
      return ' just joined  ';
    } else if (this.notification.verb === constants.notificationVerbs.ADDED_CHAR_WORLD) {
      return ` just added ${get(this.notification.action_object, 'info.name', '')} to   `;
    } else if (this.notification.verb === constants.notificationVerbs.QUEST_UPDATE) {
      return 'You got a quest update for ';
    } else if (this.notification.verb === constants.notificationVerbs.DECORATION_REACTION) {
      return ' reacted to';
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_MILESTONE) {
      if (this.notification.data.milestone_count === 1) {
        return 'One person entered your';
      } else {
        return `${this.notification.data.milestone_count} people have entered your`;
      }
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_ENDED) {
      return ` raffle has ${this.notification.target.status}!!`;
    } else if (this.notification.verb === constants.notificationVerbs.GIFTED_VIRTUAL_ITEMS) {
      return `gifted you a ${this.notification.target.title} ${this.notification.data.gift_object.replace(/_/g, ' ')}!`;
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_WINNER) {
      return ` won the `;
    } else if (this.notification.verb === constants.notificationVerbs.GIFTED_ITEM) {
      return `gifted you a ${this.notification.data.gift_object.replace(/_/g, ' ')}!`;
    } else if (this.notification.verb === constants.notificationVerbs.THANKS_GIFT_MSG) {
      return `expresses gratitude for your ${this.notification.data.gift_object.replace(/_/g, ' ')} gift`;
    } else if (this.notification.verb === constants.notificationVerbs.SF_ACTIVITY_CREATED) {
      if (['created_character'].includes(get(this.notification.action_object, 'action'))) {
        return ` just published ${this.notification.target.info.name}. `;
      } else if (
        ['created_social_space_world', 'created_world', 'created_story_world'].includes(
          get(this.notification.action_object, 'action')
        )
      ) {
        return ` just designed ${get(this.notification.action_object, 'entity.name', '')}. `;
      } else if (this.notification.action_object?.action === 'created_marketplacelisting') {
        return ` listed ${this.notification.action_object.entity.title} on marketplace. `;
      } else if (this.notification.action_object?.action === 'created_blab') {
        return ` posted ${this.notification.action_object.entity.title}. `;
      } else if (this.notification.action_object?.action === 'sunshined_reply') {
        return ` sunshined ${this.notification.action_object.extra.reply.user}'s reply. `;
      } else if (
        ['updated_char_text', 'updated_char_fields', 'updated_char_visuals', 'updated_char_profile_img'].includes(
          get(this.notification.action_object, 'action')
        )
      ) {
        return ` has posted an update for ${this.notification.action_object.entity.info.name}. `;
      } else if (
        [
          'updated_world_text',
          'updated_story_world_text',
          'updated_social_world_text',
          'updated_world_fields',
          'updated_world_visuals',
          'updated_world_profile_img',
        ].includes(get(this.notification.action_object, 'action'))
      ) {
        return ` has posted an update for ${this.notification.action_object.entity.name}.`;
      } else if (this.notification.action_object?.action === 'fanart_submitted') {
        if (get(this.notification.action_object, 'entity.type') === 'story') {
          return ` has submitted FanWork for ${get(this.notification.action_object, 'entity.name', '')}. `;
        } else {
          return ` has submitted FanWork for ${get(this.notification.action_object, 'entity.info.name', '')}. `;
        }
      } else if (this.notification.action_object?.action === 'fanart_featured') {
        if (get(this.notification.action_object, 'entity.type') === 'story') {
          return ` featured new fan art for ${this.notification.action_object.entity.name}. `;
        } else {
          return ` featured new fan art for ${this.notification.action_object.entity.info.name}. `;
        }
      }
    }
  }

  public get action2() {
    if (this.notification.verb === constants.notificationVerbs.WORLD_REACTION) {
      return worldReactionsNames[get(this.notification.action_object, 'reaction')];
    } else if (
      [constants.notificationVerbs.IMAGE_REACTION, constants.notificationVerbs.FANART_REACTION].includes(
        this.notification.verb
      )
    ) {
      return visualReactionsNames[this.notification.action_object.reaction];
    } else if (
      [constants.notificationVerbs.BLAB_REACTION, constants.notificationVerbs.BLAB_REPLY_REACTION].includes(
        this.notification.verb
      ) &&
      get(this.notification.action_object, 'reaction')
    ) {
      return blabReactionsNames[this.notification.action_object.reaction];
    } else if (get(this.notification.action_object, 'reaction')) {
      return reactionsNames[this.notification.action_object.reaction];
    } else if (get(this.notification.action_object, 'commented_as')) {
      return this.truncateCommentText(get(this.notification.action_object, 'text', ''));
    } else if (this.notification.verb === constants.notificationVerbs.RC_CHAR_REQ) {
      return this.truncateCommentText(get(this.notification.action_object, 'message', ''));
    } else if (this.notification.verb === constants.notificationVerbs.RC_STARTER_REQ) {
      return this.truncateCommentText(get(this.notification.target, 'description', ''));
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_REPLY_REACTION) {
      return blabReactionsNames[this.notification.action_object.reaction];
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_SHINE_REPLY) {
      return this.truncateReplyText(stripHtmlTags(get(this.notification.action_object, 'text', '')));
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_SEND_REPLY) {
      return this.truncateReplyText(stripHtmlTags(get(this.notification.action_object, 'text', '')));
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_PARENT_REPLY) {
      return this.truncateReplyText(stripHtmlTags(get(this.notification.action_object, 'text', '')));
    } else if (this.notification.verb === constants.notificationVerbs.EVENT_SUBMISSION_REVIEWED) {
      return 'Approved!';
    } else if (this.notification.verb === constants.notificationVerbs.SENT_FEEDBACK) {
      return this.notification.target.feedback_note;
    } else if (
      [constants.notificationVerbs.REACTION_FEEDBACK, constants.notificationVerbs.DECORATION_REACTION].includes(
        this.notification.verb
      )
    ) {
      return this.notification.target.feedback_reactions.join(' ');
    } else if (this.notification.verb === constants.notificationVerbs.CHATROOM_REPLY) {
      return this.truncateReplyText(stripHtmlTags(get(this.notification.action_object, 'text', '')));
    } else if (this.notification.verb === constants.notificationVerbs.THANKS_GIFT_MSG) {
      return this.truncateReplyText(stripHtmlTags(get(this.notification.data, 'response', '')));
    }
    return '';
  }

  public get notificationColor() {
    return this.notification.unread ? 'unread-style' : 'read-style';
  }

  public toActor() {
    const router = useRouter();
    if (
      [constants.notificationVerbs.CHAR_FEATURE, constants.notificationVerbs.CHAR_VISUAL].includes(
        this.notification.verb
      )
    ) {
      router.push(`/character/${get(this.notification.action_object, 'character.slug', '')}`);
    } else if (this.notification.verb === constants.notificationVerbs.CHAR_TRENDING) {
      router.push(`/character/${get(this.notification.action_object, 'slug', '')}`);
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_SEND_REPLY) {
      if (get(this.notification.action_object, 'reply_as_char.slug')) {
        router.push(`/character/${get(this.notification.action_object, 'reply_as_char.slug', '')}`);
      } else {
        router.push(`/profile/${this.notification.actor.username}`);
      }
    } else if (this.notification.verb === constants.notificationVerbs.BLAB_PARENT_REPLY) {
      if (get(this.notification.action_object, 'reply_as_char.slug')) {
        router.push(`/character/${get(this.notification.action_object, 'reply_as_char.slug', '')}`);
      } else {
        router.push(`/profile/${this.notification.actor.username}`);
      }
    } else if (this.notification.verb === constants.notificationVerbs.WORLD_TREND) {
      if (get(this.notification.target, 'type') === 'socialspace') {
        router.push({
          name: 'social-space-details',
          params: { slug: get(this.notification.action_object, 'slug') },
        });
      } else {
        router.push(`/worlds/details/${get(this.notification.action_object, 'slug', '')}`);
      }
    } else if (this.notification.verb === constants.notificationVerbs.CHAR_SECTION) {
      router.push(`/character/${get(this.notification.action_object, 'slug', '')}`);
    } else if (
      this.notification.action_object.commented_as &&
      get(this.notification.action_object, 'commented_as.type') === constants.commentedAsTypes.CHARACTER
    ) {
      router.push(`/character/${get(this.notification.action_object, 'commented_as.slug', '')}`);
    } else if (this.notification.verb === constants.notificationVerbs.ACTIVITY_CHEER_CHART) {
      if (!get(this.notification.action_object, 'action') || !get(this.notification.action_object, 'entity')) {
        // For supporting old activity selection notifications.
        if (get(this.notification.action_object, 'info.name')) {
          router.push(`/character/${get(this.notification.action_object, 'slug', '')}`);
        } else if (get(this.notification.action_object, 'name')) {
          router.push({ name: 'world-details', params: { slug: this.notification.action_object.slug } });
        } else if (get(this.notification.action_object, 'seller')) {
          router.push({
            name: 'mp-listing-details',
            params: { slug: this.notification.action_object.slug },
          });
        } else if (get(this.notification.action_object, 'title')) {
          router.push(`/posts/${get(this.notification.action_object, 'id', '')}`);
        }
      }
      // For supporting updated activity selection notifications.
      else if (
        [
          'created_character',
          'updated_char_text',
          'updated_char_fields',
          'updated_char_visuals',
          'updated_char_profile_img',
          'fanart_submitted',
        ].includes(get(this.notification.action_object, 'action'))
      ) {
        router.push(`/character/${get(this.notification.action_object.entity, 'slug', '')}`);
      } else if (['created_blab', 'sunshined_reply'].includes(this.notification.action_object.action)) {
        router.push(`/posts/${get(this.notification.action_object.entity, 'id', '')}`);
      } else if (
        [
          'created_world',
          'created_story_world',
          'updated_world_text',
          'updated_world_fields',
          'updated_world_visuals',
          'updated_world_profile_img',
          'updated_story_world_text',
        ].includes(get(this.notification.action_object, 'action'))
      ) {
        router.push({ name: 'world-details', params: { slug: this.notification.action_object.entity.slug } });
      } else if (['created_marketplacelisting'].includes(this.notification.action_object.action)) {
        router.push({
          name: 'mp-listing-details',
          params: { slug: this.notification.action_object.entity.slug },
        });
      } else if (
        ['updated_social_world_text', 'created_social_space_world'].includes(
          get(this.notification.action_object, 'action')
        )
      ) {
        router.push({
          name: 'social-space-details',
          params: { slug: this.notification.action_object.entity.slug },
        });
      }
    } else if (this.notification.verb === constants.notificationVerbs.USER_CHEER_CHART) {
      const username = get(this.notification.action_object, 'info')
        ? get(this.notification.action_object, 'author.username')
        : get(this.notification.action_object, 'seller')
        ? get(this.notification.action_object, 'seller.username', '')
        : get(this.notification.action_object, 'user.username');
      router.push(`/profile/${username}`);
    } else if (this.notification.verb === constants.notificationVerbs.HELP_CHEER) {
      const username = get(this.notification.action_object.entity, 'info')
        ? get(this.notification.action_object.entity, 'author.username')
        : get(this.notification.action_object.entity, 'seller')
        ? get(this.notification.action_object.entity, 'seller.username', '')
        : get(this.notification.action_object.entity, 'user.username');
      router.push(`/profile/${username}`);
    } else if (this.notification.verb === constants.notificationVerbs.SF_ACTIVITY_CREATED) {
      router.push(`/profile/${this.notification.actor.username}`);
    } else if (
      [
        constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_ACCEPTED,
        constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_REJECTED,
      ].includes(this.notification.verb)
    ) {
      router.push(`/profile/${this.notification.action_object.user_to.username}`);
    } else if ([constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_SENT].includes(this.notification.verb)) {
      router.push(`/profile/${this.notification.action_object.user_from.username}`);
    } else if (this.notification.verb === constants.notificationVerbs.RAFFLE_ENDED) {
      router.push({ name: 'raffle-details', params: { slug: this.notification.target.slug } });
    } else {
      router.push(`/profile/${this.notification.actor.username}`);
    }
  }

  public toCharacter(character: Character) {
    const router = useRouter();
    router.push({
      name: 'character-profile-new',
      params: { slug: character.slug },
      query: { img: this.notification.target.id, cmnt: this.notification.action_object.id },
    });
  }

  public toTarget() {
    try {
      const queryCmntObject = ['received a comment', 'replied to blab'].includes(this.notification.verb)
        ? {
            cmnt: this.notification.action_object.id,
          }
        : {};
      const router = useRouter();
      if ([constants.notificationVerbs.QUEST_UPDATE].includes(this.notification.verb)) {
        router.push({ name: 'event-task', params: { id: this.notification.target.id } });
      } else if (this.notification.verb === constants.notificationVerbs.QUEST_SUBMISSION_REACTION) {
        router.push({
          name: 'event-task',
          params: { id: this.notification.target.task },
          query: { s: this.notification.target.id },
        });
      } else if (
        [
          constants.notificationVerbs.WORLD_USER_ACCP,
          constants.notificationVerbs.WORLD_REACTION,
          constants.notificationVerbs.USER_JOINED,
          constants.notificationVerbs.WORLD_CHAR_REQ,
          constants.notificationVerbs.ADDED_CHAR_WORLD,
        ].includes(this.notification.verb)
      ) {
        if (get(this.notification.target, 'type') === 'socialspace') {
          router.push({ name: 'social-space-details', params: { slug: this.notification.target.slug } });
        } else if (get(this.notification.target, 'type') === 'story') {
          router.push({ name: 'world-details', params: { slug: this.notification.target.slug } });
        } else {
          router.push({ name: 'chatroom-details', params: { id: this.notification.target.id } });
        }
      } else if ([constants.notificationVerbs.REQUEST_ROOM].includes(this.notification.verb)) {
        router.push({ name: 'manage-social-space', params: { id: this.notification.target.world.id } });
      } else if (this.notification.verb === constants.notificationVerbs.WORLD_USER_REQ) {
        router.push({ name: 'manage-social-space', params: { id: this.notification.target.id } });
      } else if ([constants.notificationVerbs.BLAB_REACTION].includes(this.notification.verb)) {
        router.push({ name: 'post-details', params: { id: this.notification.target.id } });
      } else if ([constants.notificationVerbs.BLAB_REPLY_REACTION].includes(this.notification.verb)) {
        router.push({ name: 'post-details', params: { id: this.notification.target.blab.id } });
      } else if ([constants.notificationVerbs.BLAB_SHINE_REPLY].includes(this.notification.verb)) {
        router.push({ name: 'post-details', params: { id: this.notification.target.id } });
      } else if ([constants.notificationVerbs.BLAB_SEND_REPLY].includes(this.notification.verb)) {
        router.push({ name: 'post-details', params: { id: this.notification.target.id }, query: queryCmntObject });
      } else if ([constants.notificationVerbs.BLAB_PARENT_REPLY].includes(this.notification.verb)) {
        router.push({ name: 'post-details', params: { id: this.notification.target.blab.id } });
      } else if (this.notification.verb === constants.notificationVerbs.IMAGE_REACTION) {
        if (get(this.notification.target, 'entity.type') === 'world') {
          router.push({
            name: 'world-details',
            params: { slug: this.notification.target.entity.slug },
            query: { img: this.notification.target.id },
          });
        } else if (get(this.notification.target, 'entity.type') === 'character') {
          router.push({
            name: 'character-profile-new',
            params: { slug: this.notification.target.entity.slug },
            query: { img: this.notification.target.id },
          });
        }
      } else if ([constants.notificationVerbs.FOLLOW_USER].includes(this.notification.verb)) {
        router.push({ name: 'character-profile-new', params: { slug: this.notification.target.following.slug } });
      } else if (this.notification.verb === constants.notificationVerbs.MISSED_MESSAGES) {
        router.push({ name: 'user-spaces' });
      } else if (
        [
          constants.notificationVerbs.ACTIVITY_CHEER_CHART,
          constants.notificationVerbs.HELP_CHEER,
          constants.notificationVerbs.USER_CHEER_CHART,
          constants.notificationVerbs.SF_ACTIVITY_CREATED,
        ].includes(this.notification.verb)
      ) {
        // start
        if (
          [
            'created_character',
            'updated_char_text',
            'updated_char_fields',
            'updated_char_visuals',
            'updated_char_profile_img',
            'fanart_submitted',
            'fanart_featured',
          ].includes(get(this.notification.action_object, 'action'))
        ) {
          router.push({ name: 'character-profile-new', params: { slug: this.notification.target.slug } });
        } else if (this.notification.action_object.action === 'created_marketplacelisting') {
          router.push({
            name: 'mp-listing-details',
            params: { slug: this.notification.action_object.entity.slug },
          });
        } else if (
          [
            'created_world',
            'created_social_space_world',
            'created_story_world',
            'updated_world_text',
            'updated_world_fields',
            'updated_world_visuals',
            'updated_world_profile_img',
            'updated_social_world_text',
            'updated_story_world_text',
          ].includes(this.notification.action_object.action)
        ) {
          if (get(this.notification.target, 'type') === 'socialspace') {
            router.push({
              name: 'social-space-details',
              params: { slug: this.notification.action_object.entity.slug },
            });
          } else if (get(this.notification.target, 'type') === 'story') {
            router.push({ name: 'world-details', params: { slug: this.notification.action_object.entity.slug } });
          }
        } else if (['created_blab', 'sunshined_reply'].includes(this.notification.action_object.action)) {
          router.push(`/posts/${get(this.notification.action_object.entity, 'id', '')}`);
        }
        // end
        return;
      } else if (typeof this.notification.target.is_public === 'boolean') {
        // if target is a character:
        router.push({
          name: 'character-profile-new',
          params: { slug: this.notification.target.slug },
          query: queryCmntObject,
        });
      } else if ([constants.notificationVerbs.CHAR_SECTION].includes(this.notification.verb)) {
        // if target is homesection
        router.push({ name: 'homesection-details', params: { slug: this.notification.target.slug } });
      } else if (this.notification.verb === constants.notificationVerbs.EVENT_SUBMISSION_REVIEWED) {
        router.push({ name: 'event-details', params: { slug: this.notification.target.event_slug } });
      } else if (this.notification.verb === constants.notificationVerbs.COLLECTIBLE_RECEIVED) {
        router.push({ name: 'inventory' });
      } else if (
        [constants.notificationVerbs.SENT_FAN_ART, constants.notificationVerbs.DECORATION_RECEIVED].includes(
          this.notification.verb
        )
      ) {
        router.push({
          name: 'fan-work',
          ...([constants.notificationVerbs.DECORATION_RECEIVED].includes(this.notification.verb) && {
            query: { type: 'decoration' },
          }),
        });
      } else if (
        [
          constants.notificationVerbs.REACTION_FEEDBACK,
          constants.notificationVerbs.SENT_FEEDBACK,
          constants.notificationVerbs.SENT_DEC_FEEDBACK,
          constants.notificationVerbs.DECORATION_REACTION,
        ].includes(this.notification.verb)
      ) {
        router.push({
          name: 'fan-work',
          query: {
            id: this.notification.target.id,

            ...(constants.notificationVerbs.SENT_DEC_FEEDBACK && { type: 'oc_decoration' }),
          },
        });
      } else if ([constants.notificationVerbs.CHATROOM_REPLY].includes(this.notification.verb)) {
        router.push({ name: 'social-space-chatroom', params: { id: this.notification.target.id } });
      } else if (this.notification.verb === constants.notificationVerbs.FANART_REACTION) {
        if (get(this.notification.target.entity, 'type') === 'world') {
          router.push({
            name: 'world-details',
            params: { slug: this.notification.target.entity.slug },
          });
        } else {
          router.push({ name: 'character-profile-new', params: { slug: this.notification.target.entity.slug } });
        }
      } else if (
        [constants.notificationVerbs.COMMENT, constants.notificationVerbs.COMMENT_REPLY].includes(
          this.notification.verb
        )
      ) {
        if (this.notification.action_object.root_commented_on_type === constants.commentedAsTypes.CHARACTER) {
          router.push({
            name: 'character-profile-new',
            params: { slug: this.notification.target.slug },
            query: queryCmntObject,
          });
        } else if (this.notification.action_object.root_commented_on_type === constants.commentedAsTypes.USER) {
          router.push({
            name: 'profile',
            params: { username: this.notification.target.username },
            query: queryCmntObject,
          });
        }
      } else if (
        [constants.notificationVerbs.RAFFLE_MILESTONE, constants.notificationVerbs.RAFFLE_WINNER].includes(
          this.notification.verb
        )
      ) {
        router.push({ name: 'raffle-details', params: { slug: get(this.notification.target, 'slug') } });
      } else if (this.notification.verb === constants.notificationVerbs.GIFTED_ITEM) {
        router.push({
          name: 'home',
          query: {
            giftId: this.notification.action_object.id,
            userId: get(this.notification.action_object, 'from_user.id'),
          },
        });
      } else if (this.notification.verb === constants.notificationVerbs.GIFTED_VIRTUAL_ITEMS) {
        router.push({
          name: 'home',
          query: {
            id: this.notification.action_object.id,
            userId: get(this.notification.actor, 'id'),
            itemId: get(this.notification.action_object, 'entity_id'),
          },
        });
      }
    } catch (e) {
      logger.error({
        e,
        loc: 'NotificationCard.toTarget',
        msg: 'NotificationCard toTarget error',
        data: {
          notificationTarget: this.notification.target,
        },
      });
    }
  }

  public toActionPage() {
    const router = useRouter();
    if (
      [constants.notificationVerbs.RC_CHAR_REQ, constants.notificationVerbs.RC_STARTER_REQ].includes(
        this.notification.verb
      )
    ) {
      router.push({ name: 'rolechat-req-details', params: { id: this.notification.action_object.id } });
    } else if (
      [
        constants.notificationVerbs.SENT_FEEDBACK,
        constants.notificationVerbs.REACTION_FEEDBACK,
        constants.notificationVerbs.SENT_DEC_FEEDBACK,
      ].includes(this.notification.verb)
    ) {
      router.push({ name: 'fan-work', query: { id: this.notification.target.id } });
    }
  }

  public toAction() {
    const router = useRouter();
    if ([constants.notificationVerbs.CHAR_FEATURE].includes(this.notification.verb)) {
      router.push({ name: 'featured-characters' });
    } else if ([constants.notificationVerbs.CHAR_VISUAL].includes(this.notification.verb)) {
      router.push({ name: 'featured-visuals' });
    } else if ([constants.notificationVerbs.CHAR_TRENDING].includes(this.notification.verb)) {
      router.push({ name: 'characters', query: { tab: 'trending' } });
    } else if ([constants.notificationVerbs.WORLD_TREND].includes(this.notification.verb)) {
      if (get(this.notification.target, 'type') === 'story') {
        router.push({ name: 'worlds', query: { tab: 'trending' } });
      } else if (get(this.notification.target, 'type') === 'socialspace') {
        router.push({ name: 'social-space-trending' });
      }
    } else if (
      [constants.notificationVerbs.SENT_FAN_ART, constants.notificationVerbs.DECORATION_RECEIVED].includes(
        this.notification.verb
      )
    ) {
      router.push({ name: 'character-profile-new', params: { slug: this.notification.action_object.slug } });
    } else if ([constants.notificationVerbs.SF_ACTIVITY_CREATED].includes(this.notification.verb)) {
      if (
        [
          'created_character',
          'updated_char_text',
          'updated_char_fields',
          'updated_char_visuals',
          'updated_char_profile_img',
          'fanart_submitted',
          'fanart_featured',
        ].includes(get(this.notification.action_object, 'action'))
      ) {
        router.push({ name: 'character-profile-new', params: { slug: this.notification.target.slug } });
      } else if (this.notification.action_object.action === 'created_marketplacelisting') {
        router.push({
          name: 'mp-listing-details',
          params: { slug: this.notification.action_object.entity.slug },
        });
      } else if (
        [
          'created_world',
          'created_social_space_world',
          'created_story_world',
          'updated_world_text',
          'updated_world_fields',
          'updated_world_visuals',
          'updated_world_profile_img',
          'updated_social_world_text',
          'updated_story_world_text',
        ].includes(this.notification.action_object.action)
      ) {
        if (get(this.notification.target, 'type') === 'socialspace') {
          router.push({
            name: 'social-space-details',
            params: { slug: this.notification.action_object.entity.slug },
          });
        } else if (get(this.notification.target, 'type') === 'story') {
          router.push({ name: 'world-details', params: { slug: this.notification.action_object.entity.slug } });
        }
      } else if (['created_blab', 'sunshined_reply'].includes(this.notification.action_object.action)) {
        router.push(`/posts/${get(this.notification.action_object.entity, 'id', '')}`);
      }
    }
  }

  public hasActionLink() {
    return [
      constants.notificationVerbs.CHAR_FEATURE,
      constants.notificationVerbs.CHAR_VISUAL,
      constants.notificationVerbs.CHAR_TRENDING,
      constants.notificationVerbs.WORLD_TREND,
      constants.notificationVerbs.SF_ACTIVITY_CREATED,
    ].includes(this.notification.verb);
  }

  public hasActionPageLink() {
    return [
      constants.notificationVerbs.RC_CHAR_REQ,
      constants.notificationVerbs.RC_STARTER_REQ,
      constants.notificationVerbs.SENT_FEEDBACK,
      constants.notificationVerbs.SENT_DEC_FEEDBACK,
      constants.notificationVerbs.REACTION_FEEDBACK,
      constants.notificationVerbs.QUEST_UPDATE,
    ].includes(this.notification.verb);
  }

  public truncateCommentText(value: string) {
    return truncate(value, { length: 50, separator: /,? +/ });
  }

  public truncateReplyText(value: string) {
    return truncate(value, { length: 50, separator: /,? +/ });
  }

  public get isAcceptedRelationshipRequest() {
    return this.notification.verb === 'accepted a character relationship request';
  }

  public get isDeniedRelationshipRequest() {
    return this.notification.verb === 'rejected a character relationship request';
  }

  public get isCharacterRelationshipRequest() {
    return (
      this.notification.verb === 'sent a character relationship request' ||
      this.notification.verb === 'accepted a character relationship request' ||
      this.notification.verb === 'rejected a character relationship request'
    );
  }

  public get isCustom() {
    return this.notification.verb === constants.notificationVerbs.CUSTOM;
  }

  public get isCharacterTransferRequest() {
    return [
      constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_SENT,
      constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_ACCEPTED,
      constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_REJECTED,
    ].includes(this.notification.verb);
  }

  public get isAcceptedTransferRequest() {
    return this.notification.verb === constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_ACCEPTED;
  }

  public get isDeniedTransferRequest() {
    return this.notification.verb === constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_REJECTED;
  }

  public get userInitiatedCharacterTransferRequest() {
    return this.notification.verb !== constants.notificationVerbs.CHARACTER_TRANSFER_REQUEST_SENT;
  }
}
</script>

<style lang="sass" scoped>
.accept-btn, .deny-btn, .btn-actioned
  --border-radius: 50px
.accept-btn
  --background: #00B4C5
.deny-btn
  --background: #FF3864
.notification-card
  border-radius: 10px
  color: #214163
  background: #FFF

  img
    width: 46px
    height: 46px
    border-radius: 40px
  .rest-info
    font-size: 14px
  .name, .character
    font-weight: bold
.read-style
  span
    opacity: 0.6
.unread-style
  span
    opacity: 1.0
.spinner
  width: 25px
  height: 25px
</style>
